
<template>
    <div class="managementStandardOfFranchisee">
        <div class="content">
            <div class="title center bold">{{companyName}}</div>
            <div class="title center bold">村口壹站加盟商管理规范</div>
            <div class="indent5 mt3">本《村口壹站加盟商管理规范》为双方签署的《村口壹站加盟合作协议》、《农村普惠金融服务合作协议》（以下简称“主协议”）的附件，与主协议具备同等法律效力。</div>
            <div class="indent5 ">为规范服务站及加盟商管理，统一标准、优化操作，推动服务站及服务站各项业务持续、稳定、健康发展，制订本管理规范，作为加盟商管理制度及考核规则。</div>
            <div class=" mt3 bold title">第一章 加盟商管理</div>
            <div class=" mt3 bold">一、加盟商的定义</div>
            <div class="indent5 ">加盟商是指与{{companyName}}（以下简称“村口科技”）签署《村口壹站加盟合作协议》的加盟服务站的经营者，日常经营中称呼加盟商为“站长”。</div>
            <div class=" mt3 bold">二、加盟商的职责</div>
            <div class="indent5 ">提供独立场所并简单装修，提供办公桌椅。</div>
            <div class="indent5 ">自备业务周转金，缴纳设备押金，并负责服务站运营过程中的交通、水、电、网络、电话等费用和办公耗材。</div>
            <div class="indent5 ">负责服务站日常经营，服从村口科技及村口科技合作方管理，按照规定的产品、时间、场地和标准开展业务和服务，保证设备、服务站安全。</div>
            <div class=" mt3 bold">三、日常行为管理</div>
            <div class=" bold mt3">1.服务站管理</div>
            <div class=" ">1.1设备管理</div>
            <div class=" ">（1）摆放整齐，定期打扫，及时清理。</div>
            <div class=" ">（2）保障设备运行正常，遇故障及时报修，不得擅自拆解设备。</div>
            <div class=" ">（3）监督设备使用，避免人为损坏。</div>
            <div class=" ">1.2卫生管理</div>
            <div class=" ">（1）室内整洁，每日打扫。</div>
            <div class=" ">（2）宣传单、业务用品、办公用品整齐摆放，使用后及时归位。</div>
            <div class=" ">（3）服务站内不摆放非业务相关杂物。</div>
            <div class=" ">1.3标识管理</div>
            <div class=" ">（1）门头、标识牌、制度牌等广告标识及“合规经营承诺书”、“十严禁”(下附)等物料保持齐全、悬挂规范、整洁，对污、损、丢失情况及时修补或反馈。</div>
            <div class=" ">（2）不私自印制、张贴宣传品或违规使用村口科技合作方、村口科技标识。</div>
            <div class=" ">（3）不张贴、使用其他宣传资料。</div>
            <div class=" ">1.4安防管理</div>
            <div class=" ">（1）每日检查监控设备工作情况，保证正常供电、保持联网在线，不随意调整安装位置及角度。如遇角度调整、离线报警等问题及时处理。</div>
            <div class=" ">（2）办公区门随用随锁，禁止除加盟商、村口科技员工、村口科技合作方员工以外人员进入。</div>
            <div class=" ">（3）保险柜钥匙用完拔出，密码与钥匙妥善保管，密码盘用完随手打乱。</div>
            <div class=" ">（4）每日检查门窗坚固、开关情况，发现问题及时整改。</div>
            <div class=" ">（5）定期检查服务站线路，禁止在服务站使用大功率电器。</div>
            <div class=" ">（6）每周检查灭火器等消防器材是否有效、完好状况，发现问题及时更换。</div>
            <div class=" bold mt3">2.宣传营销</div>
            <div class=" ">2.1村民服务群</div>
            <div class=" ">（1）每名加盟商需组建至少一个村民（客户）服务群，由加盟商或其家属担任群主及管理员。</div>
            <div class=" ">（2）邀请服务站管理责任人及运营负责人入群，协助宣传管理。</div>
            <div class=" ">（3）严格根据相关法律法规，管理所建服务群，发现问题及时制止或清退。</div>
            <div class=" ">2.2.营销活动</div>
            <div class=" ">（1）定期举办营销活动，每月制订营销计划，保持服务站活性、扩大宣传。</div>
            <div class=" ">（2）对于村口科技、银行组织的营销活动须积极配合，知晓意义，提前做好活动准备。</div>
            <div class=" ">（3）营销活动应明确时间、目标、资源，提前准备预热，重点人群通过线上、线下结合的形式保证到场率。</div>
            <div class=" bold mt3">3．业务管理</div>
            <div class=" ">3.1农村普惠金融业务管理</div>
            <div class=" ">3.1.1业务规范</div>
            <div class="indent5 ">经村口科技合作银行向人民银行报备，以“村口壹站”为平台，在农村建立普惠金融服务站“村口壹站”，该站点可以为村民提供小额取款、现金汇款、转账汇款、代理缴费、查询等基础支付服务。</div>
            <div class=" ">3.1.2业务办理</div>
            <div class=" ">（1）熟练掌握设备操作和业务办理流程，熟悉银行产品和规则。</div>
            <div class=" ">（2）严格按照村口科技和银行规定办理业务，按规定进行交易登记。</div>
            <div class=" ">（3）业务涉及现金必须使用点钞机当面点验，确认数额、真伪，双方无异议后再签字确认。</div>
            <div class=" ">（4）加盟商对业务及现金库存必须每日核对点验，保证账实相符。</div>
            <div class=" ">（5）掌握假币识别方法，并在服务站显著位置明示假币识别、举报投诉电话等注意事项，传授识别假币的基本方法，提高村民识别能力。</div>
            <div class=" ">（6）严格按照要求在服务站内办理业务，保证整个流程处在监控范围内。</div>
            <div class=" ">（7）办理现金业务要及时放入保险柜，大额现金（超过5万）不过夜，保证资金安全。</div>
            <div class=" ">（8）因事外出时张贴联系方式。</div>
            <div class=" ">（9）规范宣传，严格按照人民银行、合作银行以及村口科技要求的宣传口径进行准确宣传，不误导客户，不夸大宣传。</div>
            <div class=" ">3.1.3登记凭证</div>
            <div class=" ">（1）客户办理业务后，必须要求客户在交易凭条和登记簿上签字或按指纹确认。</div>
            <div class=" ">（2）妥善保管交易凭条和登记薄，按村口科技合作银行要求定期收归或销毁。</div>
            <div class=" ">3.1.4.日常管理</div>
            <div class=" ">（1）每日按时、准确通过加盟商服务群汇报当日业务情况。</div>
            <div class=" ">（2）配合村口科技和银行人员的现场巡查、电话回访工作。</div>
            <div class=" ">（3）关注服务群、公众号中发出的信息、培训课程，认真学习，提高业务技能。</div>
            <div class=" ">（4）按时参加月例会、培训会。</div>
            <div class=" ">（5）服从、配合村口科技、村口科技合作方要求的其他规范性动作。</div>
            <div class=" ">（6）禁止过度收集、记录、泄露客户信息（含身份证号、身份证照片、银行卡号）等违规行为，客户信息须妥善保存，合理使用。</div>
            <div class=" ">3.2其他业务管理</div>
            <div class="indent5 ">根据服务站业务发展，新增的其他业务规范另行约定。</div>
            <div class=" mt3 bold title">第二章 加盟商考核</div>
            <div class=" bold mt3">一、站点评分考核</div>
            <div class="indent5 ">对加盟商考核以管理员日常经营评分进行考核，总分100分。村口科技根据加盟商经营情况与合作银行按月进行评分，评分表具体内容如下：</div>
            <div class="">
                <table border="1" bordercolor="black" width="100%" align="center"  class="tableCenter">
                    <tr class="bold tableCenter" >
                        <td >序号</td>
                        <td>评价指标</td>
                        <td >指标内容</td>
                        <td>计分标准</td>
                        <td>备注</td>
                    </tr>
                    <tr >
                        <td > 1</td>
                        <td >服务点交易（10分）</td>
                        <td>服务点每月服务交易笔数。</td>
                        <td>每月不少于20笔交易，缺少一笔交易服务扣0.5分，扣完为止。</td>
                        <td>通过合作银行提供的数据评分</td>
                    </tr>
                    <tr >
                        <td >2</td>
                        <td >交易凭证保管及登记簿填写（10分）</td>
                        <td>助农点妥善保管终端打印及签字的交易凭证，业务登记簿记录完整。</td>
                        <td>缺少一笔交易凭证扣1分，业务登记簿记录缺失一笔扣1分，扣完为止。</td>
                        <td>通过合作银行提供的交易数据比对</td>
                    </tr>
                    <tr >
                        <td>3</td>
                        <td >助农点设备使用（10分）</td>
                        <td>助农点需配备保险箱点钞机，助农POS机、制度牌标识牌，且确保使用/上墙。</td>
                        <td>由于助农点原因保险箱点钞机丢失、制度牌配备后丢失的不得分。</td>
                        <td>因合作银行未要求配置相关设备及制度牌等不扣分</td>
                    </tr>
                    <tr >
                        <td >4</td>
                        <td >监控联网 （10分）</td>
                        <td>实现助农点监控的联网。</td>
                        <td>实现联网得满分，未实现联网不得分。</td>
                        <td>村委等若有监控不扣分</td>
                    </tr>
                    <tr >
                        <td >5</td>
                        <td >业务推动 （10分）</td>
                        <td>借记卡推荐开卡、数字人民币新开户、手机银行新开户</td>
                        <td>新增1户业务指标，得0.5分。</td>
                        <td>通过分行提供的数据评分</td>
                    </tr>
                    <tr >
                        <td >6</td>
                        <td >日常经营 （10分）</td>
                        <td>按照合作银行要求，确保工作日助农点开门营业，且管理员在现场提供服务</td>
                        <td>巡检时发现助农点关闭未营业，每发生一次扣5分，扣完为止。</td>
                        <td>通过村口科技日常巡检记录照片评分（管理员每月至少与村口科技业务员合影一次）</td>
                    </tr>
                    <tr >
                        <td >7</td>
                        <td >参加月例会或培训会 （10分）</td>
                        <td>按照合作银行要求，助农点管理员应每月参加甲方及合作银行组织的月例会或培训会</td>
                        <td>助农点参加月例会或者培训会的得10分，未参加的不得分。没有条件参加集中会议的，助农点现场参加一对一培训，同样得10分。</td>
                        <td></td>
                    </tr>
                    <tr >
                        <td >8</td>
                        <td >助农点风险防范 （10分）</td>
                        <td>助农点确保当月无风险事件、合规事件、安防事件、违纪事件和客户投诉等发生。</td>
                        <td>若经确认为管理员责任，则该项不得分，其余情况酌情扣分。</td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class=" mt3 bold">二、服务费核算</div>
            <div class="indent5 ">村口科技根据加盟商经营情况与合作分行按月进行评分，每一评分对应服务费金额（单位：元）如下：</div>
            <div class="">
                <table border="1" bordercolor="black" width="100%" align="center"  class="tableCenter">
                    <tr class="bold tableCenter" >
                        <td>评分</td>
                        <td>≥90</td>
                        <td>89</td>
                        <td>88</td>
                        <td>87</td>
                        <td>86</td>
                        <td>85</td>
                        <td>84</td>
                        <td>83</td>

                    </tr>
                    <tr  >
                        <td>服务费</td>
                        <td>5000</td>
                        <td>4900</td>
                        <td>4800</td>
                        <td>4700</td>
                        <td>4600</td>
                        <td>4500</td>
                        <td>4400</td>
                        <td>4300</td>

                    </tr>
                    <tr class="bold tableCenter" >
                        <td>评分</td>
                        <td>82</td>
                        <td>81</td>
                        <td>80</td>
                        <td>79</td>
                        <td>78</td>
                        <td>77</td>
                        <td>76</td>
                        <td>75</td>

                    </tr>
                    <tr  >
                        <td>服务费</td>
                        <td>4200</td>
                        <td>4100</td>
                        <td>4000</td>
                        <td>3900</td>
                        <td>3800</td>
                        <td>3700</td>
                        <td>3600</td>
                        <td>3500</td>

                    </tr>
                    <tr class="bold tableCenter" >
                        <td>评分</td>
                        <td>74</td>
                        <td>73</td>
                        <td>72</td>
                        <td>71</td>
                        <td>70</td>
                        <td>69</td>
                        <td>68</td>
                        <td>67</td>

                    </tr>
                    <tr  >
                        <td>服务费</td>
                        <td>3400</td>
                        <td>3300</td>
                        <td>3200</td>
                        <td>3100</td>
                        <td>3000</td>
                        <td>2900</td>
                        <td>2800</td>
                        <td>2700</td>

                    </tr>
                    <tr class="bold tableCenter" >
                        <td>评分</td>

                        <td>66</td>
                        <td>65</td>
                        <td>64</td>
                        <td>63</td>
                        <td>62</td>
                        <td>61</td>
                        <td>60</td>
                        <td><60</td>
                    </tr>
                    <tr  >
                        <td>服务费</td>
                        <td>2600</td>
                        <td>2500</td>
                        <td>2300</td>
                        <td>2100</td>
                        <td>1900</td>
                        <td>1700</td>
                        <td>1500</td>
                        <td>500</td>
                    </tr>
                </table>
                <div class="indent5 ">为积极响应监管要求，对积极组织金融消费者权益保护、辨别非法集资等宣传活动的加盟商，村口科技与合作方银行协同进行评比，对月服务评分95分（含）以上的，每月给予一定的服务费补偿，具体补偿金额以通知为准。</div>
                <div class="indent5 ">加盟商各项服务费以村口科技合作银行提供的并确定结费的数据为准。</div>
            </div>
            <div class=" mt3 title bold">第三章 加盟商退出</div>
            <div class="bold mt3">一、退出的类型</div>
            <div class="bold mt3 ">1．考核退出</div>
            <div class="indent5 ">站点月度考核符合以下标准的，公司有权对站点启动退出流程。</div>
            <div class=" ">（1）加盟商当月行为考核得分为 0 的；</div>
            <div class=" ">（2）加盟商连续两个月行为考核得分 50 分以下的；</div>
            <div class=" ">（3）加盟商连续三个月行为考核得分 60 分以下的。</div>
            <div class="bold mt3 ">2.其他退出</div>
            <div class="indent5 ">站点符合以下情况范围内的，公司有权对站点启动退出流程。另若因其行为对合作银行、公司、客户造成损失的，加盟商应承担相应法律责任并进行赔偿。</div>
            <div class=" ">（1）对违反“十严禁”规定的；</div>
            <div class=" ">（2）经查实有违法犯罪记录的或运营期间发生违法犯罪行为的；</div>
            <div class=" ">（3）其他符合与加盟商签订的《村口壹站加盟合作协议》中退出约定条件的。</div>
            <div class="bold mt3 ">二、退出流程</div>
            <!--<div class="bold mt3 ">1．村口科技合作方负责</div>-->
            <div class=" ">（1）根据站点业绩与相关协议核对公司收益，并按照双方合作协议约定结算时间进行结清。</div>
            <div class=" ">（2）对加盟商名下的所有客户进行回访，对客户资产进行核对。</div>
            <div class=" ">（3）就农村普惠金融服务业务，合作银行要收回或者终止 POS 商户协议，撤下银行标识牌，收回在服务站布放的银行设备和投放的宣传资料。</div>
            <div class=" ">（4）在站点原址及村委张贴项目退出公告，告知村民该站点已撤销，不再办理业务，并引导村民临时到附近的站点办理业务。</div>
            <div class="bold mt3 ">三、退出服务费结算</div>
            <div class="indent5 ">加盟商退出时，须签署《合同终止协议书》，服务费按照村口科技合作银行提供并确定的结费规则核算结费。</div>
            <div class="bold mt3">附件</div>
            <div class=" ">《加盟商十严禁》及《农村普惠金融业务合规经营承诺书》</div>
            <div class="title center bold mt3">加盟商十严禁</div>
            <div class="mt3">一、严禁以任何形式组织或参与非法集资、民间融资、高利贷、违规担保等活动。</div>
            <div class="mt3">二、严禁未经客户本人同意为其办理银行业务，严禁编造、虚构客户信息资料。</div>
            <div class="mt3">三、严禁代理客户保管存折、存单、银行卡、密码、身份证件、网银认证工具等。</div>
            <div class="mt3">四、严禁代替客户在各种凭条上签名及代替客户设置或修改密码。</div>
            <div class="mt3">五、严禁过度收集、窃取和泄露客户信息等违规行为。</div>
            <div class="mt3">六、严禁为客户打白条，不及时将客户资金入账。</div>
            <div class="mt3">七、严禁利用服务点支付终端等设备进行欺诈、套现等不正当交易。</div>
            <div class="mt3">八、严禁使用假币对外支付。</div>
            <div class="mt3">九、严禁借用村口科技合作方名义销售任何非村口科技合作方相关金融产品。</div>
            <div class="mt3">十、严禁参与黄、赌、毒活动及邪教组织。</div>
            <div class=" "></div>
            <div class="title center bold mt3">农村普惠金融业务合规经营承诺书</div>
            <div class="mt3">为促进普惠金融服务健康合规发展，现做出以下承诺：</div>
            <div class="indent5">1.保证合法合规开展业务，不向客户收取任何额外费用，不利用合作方银行与村口科技名义牟取不正当收益。</div>
            <div class="indent5">2.保证不参与任何形式的民间借贷、非法集资、高利贷、违规担保或者其他高危金融活动。</div>
            <div class="indent5">3.保证不打白条办理业务，不挪用客户资金，保证客户办理业务实时到账。</div>
            <div class="indent5">4.保证不代客户保管银行卡、存折、网银认证工具等产品，不代客户签名及设置或修改密码。</div>
            <div class="indent5">5.保证不以任何方式泄露商业秘密和客户资料，不损害客户、合作方银行和村口科技的正当利益。</div>
            <div class="indent5">6.保证不同时经营其他银行或其他与村口科技普惠金融相关业务。</div>
            <div class="indent5">7.保证不参与黄、赌、毒活动及邪教组织。</div>
            <div class="indent5">8.本人自愿遵守本承诺，接受大家监督。若有违反，本人自愿承担撤除服务点、追究法律责任等后果。</div>
            <div class="mt3">承诺人：{{agreementParams.para_Id_Name}}</div>
            <div class="mt3">承诺日期： {{agreementParams.para_date_year}} 年  {{agreementParams.para_date_month}}  月  {{agreementParams.para_date_day}}  日</div>

        </div>
        <div class="footer" v-show="signatureShow">
            <van-button type="info" color="#3F7C53" @click="signatureClick">签名</van-button>
        </div>
    </div>
</template>

<script>
    import {COMPANY_NAME} from '@/utils/constantNum'
    import { Button } from 'vant'
    import common from '@/utils/common'
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name:'newManagementStandardOfFranchiseeZH',
        components:{
            [Button.name]: Button
        },
        data() {
            return {
                signatureShow: false,
                agreementParams: {},
                exampleStr:'',
                companyName:COMPANY_NAME,
            }
        },
        mounted(){
            this.$nextTick(()=>{
                this.signatureShow = this.$route.query.sign == 'sign' ? true : false
                if(this.$route.query.agreementParams){
                    this.agreementParams = JSON.parse(this.$route.query.agreementParams)
                }
            })
            uwStatisticAction('/agreement/newManagementStandardOfFranchiseeZH','附件二：村口壹站加盟商管理规范')
        },
        methods: {
            signatureClick() {
                this.$router.push({name:'signatureFour', query:{stationNo: this.$route.query.stationNo}})
            }
        }
    }
</script>

<style lang="less" scoped>
    .managementStandardOfFranchisee{
        background: #f5f5f5;
        font-size: 0.3rem;
        box-sizing: border-box;
        padding: 20px 0.3rem 100px 0.3rem ;
        -webkit-overflow-scrolling: touch;
        .content{
            .title{
                font-size: 0.4rem;
            }
            .bold{
                font-weight: bold
            }
            .center{
                text-align: center;
            }
            .indent5{
                text-indent: 0.6rem;
            }
            .mt3{
                margin-top: 0.3rem;
            }
            .underline{
                text-decoration: underline;
            }
            .tableCenter{
                vertical-align:middle;
                text-align:center;
            }
            table{
                td{
                    padding:2px
                }
            }
        }
        .footer{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            .van-button{
                width: 100%;
            }
        }
    }
</style>
